import { storeUser } from '@/stores/user.js'
import $axios from '@/axios'

export default {
  //請求
  get: async function () {
    await $axios
      .post('/api/apis/user_get_data.php')
      .then(function (res) {
        if (res.data.result == 'pass') {
          storeUser.user = res.data.user
        } else {
          console.log(res.data)
        }
      })
      .catch(function (err) {
        console.log(err)
      })
  }
}
