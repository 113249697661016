import pinia from '@/stores/index.js'
import { defineStore } from 'pinia'

const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: {},
      perPage: 20,
      tables: [{ queryDatas: [], queryObj: {}, page: 1, pageCount: 0 }]
    }
  }
})

export const storeUser = useUserStore(pinia)
