//导入axios
import axios from "axios";
import { storeSys } from "@/stores/sys.js";
import router from "@/router";
import { msgBox } from "@/components/MyMsgBox/MyMsgBox.js";

const $axios = axios.create({
  //   baseURL: process.env.BASE_URL, // api的base_url
  timeout: 5000, // 請求超時時間
});

/****** request攔截器 ******/
$axios.interceptors.request.use(
  (config) => {
    // storeSys.loadingScreen = true;
    if (storeSys.token != "") {
      config.headers.Authorization = storeSys.token;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    if (response.data !== undefined) {
      if (response.data.result !== undefined) {
        if (response.data.token !== undefined) {
          storeSys.token = response.data.token;
        }
        if (response.data.result == "Token expired" || response.data.result == "Invalid Token") {
          storeSys.token = "";
          localStorage.setItem("token", JSON.stringify(storeSys.token));
          router.push("/login");
          msgBox("已逾期，請重新登入", "error");
        }
      }
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// $axios.interceptors.response.use(
//   (res) => {
//     // storeSys.loadingScreen = false;
//     // switch (res.status) {
//     //   case 200:
//     return Promise.resolve(res);
//     //   default:
//     //     console.log(res.status)
//     // }
//   },
//   (error) => {
//     // if (error && error.response) {
//     //   switch (error.response.status) {
//     //     case 400:
//     //       console.error(error.response)
//     //       break
//     //     case 401:
//     //       console.error(error)
//     //       break
//     //     default:
//     //       console.error('攔截錯誤請求', error.response.status)
//     //       return Promise.reject(error)
//     //   }
//     // }
//   }
// );

export default $axios;
