import { createApp } from 'vue'
import App from '@/App.vue'

import pinia from '@/stores'
import router from '@/router'
import $axios from '@/axios'

//element plus 所需的
import ElementPlus from 'element-plus'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import 'element-plus/dist/index.css'
import '@/assets/main.css'

//导入Svg图片插件，可以在页面上显示Svg图片
import 'virtual:svg-icons-register'

//匯入自定義的方法
import { msgBox, confirmMsgBox } from './components/MyMsgBox/MyMsgBox'
import { smoothScrollTop, smoothScrollBottom } from './js/global_function.js'

const app = createApp(App)
app.use(pinia)
app.use(router)
// app.use(ElementPlus)

app.use(ElementPlus, {
  locale: zhTw
})

//全局註冊方法，在需要用到的地方可以用inject來呼叫
app.provide('$global', { msgBox, confirmMsgBox, smoothScrollTop, smoothScrollBottom })
app.provide('$axios', $axios)

app.mount('#app')
