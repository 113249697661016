import { createRouter, createWebHistory } from 'vue-router'
import { storeSys } from '@/stores/sys.js'
// const storeSys = useSysStore(pinia);
import apis from '@/apis/index.js'
import { nextTick } from 'vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue'), //懶加載
      meta: { auth: false }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/Dashboard.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('../views/pages/Home.vue')
        }
      ]
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/Error.vue'),
      meta: { auth: true }
    },
    {
      path: '/:catchAll(.*)', // 此處需特别注意至於最底部
      redirect: '/404'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (storeSys.token == '' || storeSys.lastUser == '') {
    if (localStorage.getItem('token') != undefined) {
      storeSys.$patch((state) => {
        state.token = JSON.parse(localStorage.getItem('token'))
        state.lastUser = JSON.parse(localStorage.getItem('lastUser'))
          ? JSON.parse(localStorage.getItem('lastUser'))
          : ''
      })
    }
  }

  if (from.fullPath == '/' && to.fullPath == '/login') {
    if (storeSys.token !== '') {
      next('/dashboard')
    } else {
      clearToken()
      next()
    }
  } else if (
    from.fullPath.split('/')[1] == 'dashboard' &&
    (to.fullPath == '/' || to.fullPath == '/login')
  ) {
    if (storeSys.token !== '') {
      next(false)
    } else {
      clearToken()
      next()
    }
  } else if (from.fullPath == '/login' && to.fullPath.split('/')[1] == 'dashboard') {
    if (storeSys.token != '') {
      await requestData(to)
      next()
    } else {
      clearToken()
      next(false)
    }
  } else {
    if (storeSys.token != '') {
      await requestData(to)
      next()
    } else {
      clearToken()
      next('/login')
    }
  }
})

function clearToken() {
  storeSys.$patch((state) => {
    state.token = ''
  })
  localStorage.setItem('token', JSON.stringify(storeSys.token))
}

async function requestData(to) {
  nextTick(() => {
    storeSys.loadingText = '載入中'
    storeSys.loadingScreen = true
  })

  switch (to.name) {
    case 'products':
      await apis.productApi.get(to.path, to.query)
      await apis.categoryApi.get()
      break
  }

  setTimeout(() => {
    storeSys.loadingText = ''
    storeSys.loadingScreen = false
  }, 500)
}

export default router
