import pinia from "@/stores/index.js";
import { defineStore } from "pinia";

const useSysStore = defineStore("sys", {
  state: () => {
    return {
      token: "",
      lastUser: "",
      screenWidth: document.documentElement.clientWidth,
      loadingScreen: false,
      loadingText: "",
    };
  },
});

export const storeSys = useSysStore(pinia);
