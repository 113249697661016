<script setup>
import { onBeforeMount } from 'vue'
import { RouterView } from 'vue-router'
import { storeSys } from '@/stores/sys.js'
import MyLoading from '@/components/MyLoading.vue'

// onMounted(() => {
//   document.body.style.setProperty("--el-color-primary", "#1976D2");
// });

//監聽頁面刷新的事件
onBeforeMount(() => {
  window.addEventListener('beforeunload', () => {
    localStorage.setItem('token', JSON.stringify(storeSys.token))
    localStorage.setItem('lastUser', JSON.stringify(storeSys.lastUser))
  })
})
</script>

<template>
  <RouterView class="app" />
  <MyLoading v-show="storeSys.loadingScreen"></MyLoading>
</template>

<style scoped>
.app {
  position: absolute;
  inset: 0;
}
</style>
