import { ElMessage, ElMessageBox } from "element-plus";
import "@/components/MyMsgBox/MyMsgBox.css";

//消息提示
export function msgBox(msg, type = "success", duration = 2000) {
  ElMessage({
    type: type,
    message: msg,
    grouping: true,
    duration: duration,
  });
  let typeStyle = "";
  switch (type) {
    case "success":
      typeStyle =
        "  --el-message-bg-color: #0c9c13; --el-message-border-color: #0c9c13; --el-message-text-color: #fff; ";
      break;
    case "error":
      typeStyle =
        "  --el-message-bg-color: #e90000; --el-message-border-color: #e90000; --el-message-text-color: #fff; ";
      break;
  }
  var el = document.querySelectorAll(".el-message");
  if (el) {
    for (var i = 0; i < el.length; i++) {
      el[i].style.cssText = typeStyle;
    }
  }
}
//確認彈窗
export function confirmMsgBox(title, msg, yesBtnLabel = "確定", noBtnLabel = "取消", type = "delete") {
  return ElMessageBox.confirm(msg, title, {
    type: getType(type),
    confirmButtonText: yesBtnLabel,
    cancelButtonText: noBtnLabel,
    // closeOnClickModal: false,
    // closeOnPressEscape: false,
    dangerouslyUseHTMLString: true,
    showClose: false,
    autofocus: false,
    confirmButtonClass: getClass(type)[0],
    cancelButtonClass: getClass(type)[1],
    icon: getIcon(type),
  });
}

function getType(type) {
  switch (type) {
    case "delete":
    case "danger":
      return "error";
    case "warning":
      return "warning";
    default:
      return "";
  }
}

function getIcon(type) {
  switch (type) {
    case "delete":
      return "Delete";
    case "danger":
      return "WarningFilled";
    default:
      return "";
  }
}

function getClass(type) {
  switch (type) {
    case "delete":
    case "danger":
      return ["yes-red", "no-red"];
    default:
      return ["", ""];
  }
}
