import { nextTick } from 'vue'

export function smoothScrollTop(domObj) {
  nextTick(() => {
    domObj.value.scrollTo({ top: 0, behavior: 'smooth' })
  })
}

export function smoothScrollBottom(domObj) {
  nextTick(() => {
    domObj.value.scrollTo({ top: 2000, behavior: 'smooth' })
  })
}

export function getAssetsFile(url) {
  return new URL(`../assets/${url}`, import.meta.url).href
}
